define("discourse/plugins/discourse-activity-pub/discourse/templates/preferences/activity-pub", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{body-class "user-preferences-activity-pub-page"}}
  
  <ActivityPubAuthorize />
  
  {{#if this.hasAuthorizations}}
    <div class="activity-pub-authorizations">
      <h4>{{i18n "user.discourse_activity_pub.authorizations.title"}}</h4>
      <label>{{i18n
          "user.discourse_activity_pub.authorizations.description"
        }}</label>
      <ul>
        {{#each this.authorizations as |authorization|}}
          <li>
            <ActivityPubAuthorization
              @authorization={{authorization}}
              @remove={{action "removeAuthorization"}}
            />
          </li>
        {{/each}}
      </ul>
    </div>
  {{/if}}
  */
  {
    "id": "T7x7Wvrg",
    "block": "[[[1,[28,[35,0],[\"user-preferences-activity-pub-page\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"hasAuthorizations\"]],[[[1,\"  \"],[10,0],[14,0,\"activity-pub-authorizations\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,[28,[35,5],[\"user.discourse_activity_pub.authorizations.title\"],null]],[13],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,5],[\"user.discourse_activity_pub.authorizations.description\"],null]],[13],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,0,[\"authorizations\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[8,[39,11],null,[[\"@authorization\",\"@remove\"],[[30,1],[28,[37,12],[[30,0],\"removeAuthorization\"],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"authorization\"],false,[\"body-class\",\"activity-pub-authorize\",\"if\",\"div\",\"h4\",\"i18n\",\"label\",\"ul\",\"each\",\"-track-array\",\"li\",\"activity-pub-authorization\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/templates/preferences/activity-pub.hbs",
    "isStrictMode": false
  });
});